import {
  FavoriteStore,
  OrderMyJoe,
  OrderMyJoeItem,
  UsualItem,
} from 'types/api'
import { getItemHash } from './cart'
import { Item } from 'types/api/cart'
import { TagProps } from 'components/Tag'

const ORDER_STATUS: Record<string, string> = {
  new: 'New',
  accepted: 'Accepted',
  rejected: 'Rejected',
  cancelled_store: 'Cancelled',
  cancelled_customer: 'Cancelled',
  completed: 'Completed',
}

const ORDER_STATUS_COLOR: Record<string, 'red' | 'blue' | 'yellow' | 'green'> =
  {
    new: 'blue',
    accepted: 'blue',
    rejected: 'red',
    cancelled_store: 'red',
    cancelled_customer: 'red',
    completed: 'blue',
  }

const ORDER_STATUS_VARIANT: Record<string, TagProps['variant']> = {
  new: 'info',
  accepted: 'info',
  rejected: 'error',
  cancelled_store: 'error',
  cancelled_customer: 'error',
  completed: 'info',
}

export const mapOrderStatus = (status: string) => {
  return ORDER_STATUS[status] || status
}

export const getOrderStatusColor = (status: string) => {
  return ORDER_STATUS_COLOR[status] || 'blue'
}

export const getOrderStatusVariant = (status: string): TagProps['variant'] => {
  return ORDER_STATUS_VARIANT[status] || 'info'
}

export const getCartItemHash = (cartItem: Item, storeId: string) => {
  return getItemHash({
    storeId,
    menuItemId: cartItem.itemOriginalId,
    menuItemSizeId: cartItem.modifiers.find(({ modId }) => modId === 'sizes')
      ?.choiceId as string,
    notes: cartItem.modifiers.find(
      ({ modId }) => modId === 'special-instructions'
    )?.name,
    choices: cartItem.modifiers
      .filter(
        ({ modId }) => modId !== 'special-instructions' && modId !== 'sizes'
      )
      .map(({ quantity, modId, choiceId }) => ({
        quantity: quantity || 1,
        menuOptionId: modId,
        menuOptionChoiceId: choiceId,
      })),
  })
}

export const getUsualItemHash = (usualItem: UsualItem, storeId: string) =>
  getItemHash({
    storeId,
    menuItemId: usualItem.id,
    menuItemSizeId: usualItem.size.id,
    notes: usualItem.note ?? '',
    choices: usualItem.choices.map(
      ({ id, optionId, quantity }) => ({
        menuOptionId: optionId,
        menuOptionChoiceId: id,
        quantity:
          // istanbul ignore next
          Number(quantity) || 1,
      })
    ),
  })

export const getRecentOrderItemHash = (
  recentOrderItem: OrderMyJoeItem,
  storeId: string
) =>
    getItemHash({
    storeId,
    menuItemId: recentOrderItem.id,
    menuItemSizeId: recentOrderItem.size.id,
    notes: recentOrderItem.note ?? '',
    choices: recentOrderItem.modifiers.map(({ id, optionId, quantity }) => ({
      menuOptionId: optionId,
      menuOptionChoiceId: id,
      // istanbul ignore next
      quantity: Number(quantity) || 1,
    })),
  })

export const getUsualItemHashmap = (items: OrderMyJoeItem[], storeId: string) => {
  return items.reduce((hashmap, item) => {
    // istanbul ignore next
    if (item.usualItemId) {
      hashmap.set(
        getRecentOrderItemHash(item, storeId),
        item.usualItemId
      )
    }

    return hashmap
  }, new Map())
}

export const populateUsualItemIdForRecentOrders = (
  recentOrders: OrderMyJoe[],
  favoriteStores: FavoriteStore[],
): OrderMyJoe[] => {
  const usualItemHashMap = favoriteStores.reduce(
    (hashMap, store) =>
      store.items.reduce((hashMap, usualItem) => {
        hashMap.set(
          getUsualItemHash(usualItem, store.store_id),
          usualItem.usualItemId
        )

        return hashMap
      }, hashMap),
    new Map()
  )

  return recentOrders.map((order) => ({
    ...order,
    items: order.items.map((item) => {
      const usualItemId = usualItemHashMap.get(
        getRecentOrderItemHash(item, order.storeId)
      )

      // istanbul ignore next
      if (usualItemId) {
        return {
          ...item,
          usualItemId,
        }
      }

      return item
    }),
  }))
}
