// istanbul ignore file
import { api } from 'config/apiClient'
import { cartState } from 'config/states'
import useNotification from 'hooks/useNotification'
import { clone, omit } from 'lodash'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { OrderMyJoeItem } from 'types/api'
import { AddUsualItemRequest } from 'types/api/item'
import { getItemHash } from 'utils/cart'
import { logError } from 'utils/honeybadger'
import { getCartItemHash } from 'utils/order'
import { v4 as uuid } from 'uuid'

export const useMarkItemAsUsual = () => {
  const { notifyError } = useNotification()
  const [cart, setCart] = useRecoilState(cartState)

  const addUsualItem = useCallback(async ({
    item,
    storeId
  }: {
    item: {
      itemOriginalId: string
      modifiers: {
        choiceId: string
        modId: string
        name: string
        quantity?: number
      }[]
    }
    storeId: string
  }) => {
    const itemSize = item.modifiers.find(({ modId }) => modId === 'sizes')

    if (!itemSize) {
      // what kind of item with no size???
      return
    }

    const note = item.modifiers.find(({ modId }) => modId === 'special-instructions')
    const modifiers = item.modifiers.filter(({ modId }) => modId !== 'special-instructions' && modId !== 'sizes')

    const clonedCart = clone(cart)

    const requestData: AddUsualItemRequest = {
      storeId,
      menuItemId: item.itemOriginalId,
      menuItemSizeId: itemSize.choiceId,
      notes: note?.name,
      choices: modifiers.map(({ quantity, modId, choiceId }) => ({
        quantity: quantity || 1,
        menuOptionId: modId,
        menuOptionChoiceId: choiceId
      }))
    }

    const addedItemHash = getItemHash(requestData)

    const setCartWithUsualItemId = (usualItemId: string) => {
      setCart((cart) => ({
        ...cart,
        items: cart.items.map((cartItem) => {
          const itemHash = getCartItemHash(cartItem, storeId)

          if (itemHash === addedItemHash) {
            // identical items
            return {
              ...cartItem,
              usualItemId
            }
          }

          return cartItem
        })
      }))
    }

    const placeholderId = uuid()

    setCartWithUsualItemId(placeholderId)

    return api('addUsualItem', requestData).then(({ success, data }) => {
      if (!success) {
        // reverting cart
        setCart(clonedCart)
        notifyError('Unable to mark item as usual. Please try again later.')
        return
      }

      setCartWithUsualItemId(data.newUsualItemId)

      return {
        ...data,
        addedItemHash,
      }
    }).catch(
      (error) => {
      setCart(clonedCart)
      notifyError('Something unexpected happened. Please try again later')
      logError(error)
    })
  }, [cart, notifyError, setCart])

  const removeUsualItem = useCallback(async (usualItemId: string) => {
    const clonedCart = clone(cart)

    setCart((cart) => ({
      ...cart,
      items: cart.items.map((cartItem) => {
        if (cartItem.usualItemId === usualItemId) {
          return omit(cartItem, 'usualItemId')
        }

        return cartItem
      })
    }))

    return api('removeUsualItem', {
      usualItemId: usualItemId
    }).then(({ success }) => {
      // istanbul ignore next
      if (!success) {
        setCart(clonedCart)
        notifyError('Unable to remove marked item. Please try again later.')
        return false
      }

      return true
    }).catch(
      // istanbul ignore next
      (error) => {
      setCart(clonedCart)
      notifyError('Something unexpected happened. Please try again later')
      logError(error)
      return false
    })
  }, [cart, notifyError, setCart])

  const onRemoveUsualItem = useCallback(
    (item: OrderMyJoeItem) => {
      removeUsualItem(item.usualItemId as string)
    },
    [removeUsualItem]
  )

  const onAddUsualItem = useCallback(
    async (item: OrderMyJoeItem, storeId: string) => {
      addUsualItem({
        item: {
          itemOriginalId: item.id,
          modifiers: [
            {
              modId: 'sizes',
              choiceId: item.size.id,
              name: item.size.name,
              quantity: 1,
            },
            ...item.modifiers.map(
              ({ id, optionId, name, quantity }) => ({
                modId: optionId,
                choiceId: id,
                name,
                quantity: Number(quantity ?? 0),
              })
            ),

            ...(item.note
              ? [
                  {
                    modId: 'special-instructions',
                    name: item.note,
                    choiceId: 'special-instructions',
                  },
                ]
              : []),
          ],
        },
        storeId,
      })
    },
    [addUsualItem]
  )

  const onClickUsualItemMark = useCallback(
    async (item: OrderMyJoeItem, storeId: string) => {
      if (item.usualItemId) {
        onRemoveUsualItem(item)

        return
      }

      onAddUsualItem(item, storeId)
    },
    [onAddUsualItem, onRemoveUsualItem]
  )

  return {
    addUsualItem,
    removeUsualItem,
    onClickUsualItemMark
  }
}
