import { v4 as uuidv4 } from 'uuid'

import { cartInitialState } from 'config/states'
import { OrderRepeatResponse } from 'types/api'
import { CartData, Modifier } from 'types/api/cart'
import { ArrElement } from 'types'
import Big from 'big.js'
import { AddUsualItemRequest } from 'types/api/item'
import hash from 'object-hash'
import {
  GetOrderPricingResponse,
  OrderPricingAdditionalCharge,
} from 'types/api/order'

export const sortItemModifiers = (modifiers: Modifier[]) => {
  if (modifiers.length < 2) {
    return modifiers
  }

  const mods = [...modifiers]

  const sizeModIndex = mods.findIndex((mod) => mod.modId === 'sizes')

  if (sizeModIndex > -1) {
    const mod = mods[sizeModIndex]

    mods.splice(sizeModIndex, 1)
    mods.unshift(mod)
  }

  const specialModIndex = mods.findIndex(
    (mod) => mod.modId === 'special-instructions'
  )

  if (specialModIndex > -1) {
    const mod = mods[specialModIndex]

    mods.splice(specialModIndex, 1)
    mods.push(mod)
  }

  return mods
}

export const rebuildCart = ({
  items,
  store,
}: Pick<OrderRepeatResponse, 'store'> & {
  items: Array<
    ArrElement<OrderRepeatResponse['items']> & {
      usualItemId?: string
    }
  >
}): CartData => {
  const cartItems = items.map((item) => {
    const modifiers: Modifier[] = []

    modifiers.push({
      modId: 'sizes',
      modName: 'SIZE',
      name: item.size.name,
      price: parseFloat(item.size.price) / 100,
      choiceId: item.size.id,
    })

    modifiers.push(
      ...item.modifiers.map((modifier) => {
        // istanbul ignore next
        const quantity = modifier.quantity
          ? Math.max(Number(modifier.quantity), 1)
          : 1

        return {
          modId: modifier.optionId,
          modName: modifier.optionName,
          name: modifier.name,
          price: Big(modifier.price).div(quantity).div(100).toNumber(),
          choiceId: modifier.id,
          quantity,
        }
      })
    )

    if (item.note) {
      modifiers.push({
        modId: 'special-instructions',
        modName: 'SPECIAL INSTRUCTIONS',
        choiceId: 'special-instructions',
        name: item.note,
        price: 0,
      })
    }

    return {
      id: uuidv4(),
      modifiers,
      itemOriginalId: item.id,
      name: item.name,
      photo: item.photo,
      usualItemId: item.usualItemId,
    }
  })

  return {
    ...cartInitialState,
    items: cartItems,
    storeAddress: store.address,
    storeId: store.id,
    storeName: store.name,
    storePhoto: store.photo,
  }
}

export const getItemHash = (data: AddUsualItemRequest) => {
  return hash(data, {
    unorderedArrays: true,
  })
}

export const calculateDonationAmount = (
  orderPricing: GetOrderPricingResponse
) => {
  const totalPricePlusTaxes = Big(orderPricing.prices.itemsTotal).add(
    orderPricing.prices.additionalChargesTotal
  )

  const donationAmount = totalPricePlusTaxes
    .div(100)
    .round(0, Big.roundUp)
    .mul(100)
    .minus(totalPricePlusTaxes)
    .toNumber()

  return donationAmount === 0 ? 100 : donationAmount
}

export const calculateTipAmount = (
  orderPricing: GetOrderPricingResponse,
  additionalCharges: OrderPricingAdditionalCharge[],
  itemsTotal: string,
  donation: boolean,
  tipPercentage: number
) => {
  const taxCharge =
    additionalCharges.find((charge) => charge.category === 'tax')?.amount ??
    '0'

  const serviceFeeCharge =
    additionalCharges.find((charge) => charge.category === 'service-fee')
      ?.amount ?? '0'

  const chargesPlusDonation = donation
    ? Big(calculateDonationAmount(orderPricing))
        .add(taxCharge)
        .add(serviceFeeCharge)
    : Big(taxCharge).add(serviceFeeCharge)

  const totalWithTax = parseFloat(
    Big(itemsTotal).add(chargesPlusDonation).toString()
  )

  return Math.round((totalWithTax * tipPercentage) / 100)
}

/**
 * Tip should be fixed for every order which subtotal is less than $10.00 and percentage otherwise
 *
 * @param itemsTotal Order subtotal: sum of all items prices
 * @returns the type of the tip based on the order subtotal
 */
export const getTipType = (itemsTotal: number): 'percentage' | 'fixed' => {
  return itemsTotal > 1000 ? 'percentage' : 'fixed'
}

export const isInsufficientFundsError = (errors: string[]) =>
  errors.some((error) => error.toLowerCase().includes('balance is not enough'))
